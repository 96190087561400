import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import BibleStudyArticleStyles from "./article.module.css";


export default function BibleStudyArticle({ data }) {
  const title = data.markdownRemark.frontmatter.title;
  const description = data.markdownRemark.frontmatter.description;
  const category = data.markdownRemark.frontmatter.category;
  const html = data.markdownRemark.html;
  return (
    <Layout>
      <SEO
        keywords={[
          `${title}`,
          `${category}`,
        ]}
        title={`${title}`}
        description={`${description}`}
      />
      <h1 className="text-center font-bold text-2xl m-2">{title}</h1>
      {/* <div id={BibleBookStyles.bibleBookBody}>{RichText.render(body)}</div> */}
      <div
        id={BibleStudyArticleStyles.bibleBookBody}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  );
}

BibleStudyArticle.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        category: PropTypes.string,
      })
    }),
  }),
};

export const query = graphql`
  query($ID: String) {
    markdownRemark(id: {eq: $ID}) {
      html
      frontmatter {
        title
        description
        category
      }
    }
  }
`;
